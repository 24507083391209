<template>
  <b-card>
    <form-component
      :categories-by-group="categoriesByGroup"
      @onSubmit="onSubmit"
      @fetchByCategoryGroup="fetchByCategoryGroup" />
  </b-card>
</template>

<script>
import FormComponent from '@/components/categories/FormComponent';
import NotificationMixin from '@/mixins/NotificationMixin';
import { categoryGroups } from '@/constants/categories';
import { all as allOvercategories, edit as updateOvercategory } from '@/http/product/overcategory';
import { all as allCategories, edit as updateCategory } from '@/http/product/category';
import { all as allSubcategtories, edit as updateSubcategory } from '@/http/product/subcategory';

export default {
  mixins: [NotificationMixin],
  components: {
    FormComponent,
  },

  data() {
    return {
      categoriesByGroup: [],
    };
  },

  methods: {
    fetchByCategoryGroup(categoryGroup) {
      this.categoriesByGroup = [];

      switch (categoryGroup) {
        case categoryGroups.overcategories:
          return allOvercategories().then(({ data }) => (this.categoriesByGroup = data.data));

        case categoryGroups.categories:
          return allCategories().then(({ data }) => (this.categoriesByGroup = data.data));

        case categoryGroups.subcategories:
          return allSubcategtories().then(({ data }) => (this.categoriesByGroup = data.data));

        default:
          break;
      }
    },
    onSubmit({ category, categoryType }) {
      switch (categoryType) {
        case categoryGroups.overcategories:
          return this.overcategory(category);

        case categoryGroups.categories:
          return this.category(category);

        case categoryGroups.subcategories:
          return this.subcategory(category);

        default:
          break;
      }
    },
    overcategory(data) {
      updateOvercategory(data)
        .then(({ data: { data } }) => {
          this.refreshCategory(data);
          this.showSuccessNotification('Zaktualizowano dane', 'Dane zostały pomyślnie zaktualizowane.');
        })
        .catch(() => {
          this.showErrorNotification(
            'Problem z edycją danych',
            'Wystąpił problem z edycją danych. Skontaktuj się ze swoim developerem.',
          );
        });
    },
    category(data) {
      updateCategory(data)
        .then(({ data: { data } }) => {
          this.refreshCategory(data);
          this.showSuccessNotification('Zaktualizowano dane', 'Dane zostały pomyślnie zaktualizowane.');
        })
        .catch(() => {
          this.showErrorNotification(
            'Problem z edycją danych',
            'Wystąpił problem z edycją danych. Skontaktuj się ze swoim developerem.',
          );
        });
    },
    subcategory(data) {
      updateSubcategory(data)
        .then(({ data: { data } }) => {
          this.refreshCategory(data);
          this.showSuccessNotification('Zaktualizowano dane', 'Dane zostały pomyślnie zaktualizowane.');
        })
        .catch(() => {
          this.showErrorNotification(
            'Problem z edycją danych',
            'Wystąpił problem z edycją danych. Skontaktuj się ze swoim developerem.',
          );
        });
    },
    refreshCategory(data) {
      const category = this.categoriesByGroup.find((category) => category.id === data.id);
      category.image = data.image;
      category.custom_name = data.custom_name;
    },
  },
};
</script>
