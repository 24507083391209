<template>
  <b-form @submit.prevent="onSubmit" class="form-container">
    <b-col>
      <h4 class="mb-2">Nadpisywanie kategorii</h4>
      <b-form-group label="Grupa kategorii" label-for="v-language-select">
        <v-select
          id="v-language-select"
          label="value"
          placeholder="Wybierz grupę kategorii"
          v-model="categoryGroup"
          :clearable="false"
          :deselectFromDropdown="false"
          :options="categories"
          @input="fetchByCategoryGroup" />
      </b-form-group>

      <b-form-group label="Kategoria" label-for="v-language-select">
        <v-select
          id="v-language-select"
          label="name"
          placeholder="Wybierz kategorię"
          v-model="chosenCategory"
          :clearable="false"
          :deselectFromDropdown="false"
          :disabled="!categoryGroup"
          :options="categoriesByGroup" />
      </b-form-group>

      <b-form-group label="Nadpisana nazwa" label-for="v-custom-name">
        <b-form-input
          id="v-custom-name"
          placeholder="Nadpisana nazwa"
          v-model="chosenCategory.custom_name"
          :disabled="!chosenCategory.id" />
      </b-form-group>

      <b-form-group label="Zdjęcie nagłówkowe" label-for="v-image">
        <b-form-file
          id="v-image"
          v-model="file"
          :state="Boolean(file)"
          browse-text="Wybierz zdjęcie"
          placeholder="Wybierz lub przeciągnij zdjęcie"
          drop-placeholder="Przeciągnij tu zdjęcie"
          accept=".jpg, .png, .gif, .jpeg" />
      </b-form-group>

      <b-col class="d-flex justify-content-center align-items-center image-preview-container">
        <b-img class="image-preview mb-2 max-h-[350px]" :src="preview" />
      </b-col>
    </b-col>

    <b-row class="w-100">
      <b-col cols="12">
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          :disabled="!chosenCategory.id"
          type="submit"
          variant="primary">
          Zapisz
        </b-button>
      </b-col>
    </b-row>
  </b-form>
</template>

<script>
import { getCategoryGroups } from '@/constants/categories';
import { base64Encode } from '@/constants/utils/file';
import { convertFromURLToFile } from '@/constants/utils/file';

export default {
  props: {
    categoriesByGroup: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      preview: null,
      file: null,
      categoryGroup: null,
      categories: getCategoryGroups(),
      chosenCategory: {
        id: null,
        name: 'Wybierz kategorię',
        image: null,
        custom_name: null,
      },
    };
  },

  methods: {
    onSubmit() {
      const category = new FormData();
      category.set('slug', this.chosenCategory.slug);
      category.set('id', this.chosenCategory.id);
      category.set('custom_name', this.chosenCategory.custom_name ? this.chosenCategory.custom_name : '');

      if (this.file) {
        category.set('image', this.file);
      }

      this.$emit('onSubmit', { category, categoryType: this.categoryGroup.key });
    },
    fetchByCategoryGroup() {
      this.chosenCategory = {
        id: null,
        name: 'Wybierz kategorię',
      };
      this.$emit('fetchByCategoryGroup', this.categoryGroup.key);
    },
  },

  watch: {
    chosenCategory: {
      handler(category) {
        if (category.image) {
          convertFromURLToFile(category.image, category.slug).then((file) => (this.file = file));

          return;
        }

        this.preview = category.image;
        this.file = null;
      },
      deep: true,
    },
    file(file) {
      if (!file) {
        this.preview = null;

        return;
      }

      base64Encode(file).then((image) => {
        this.preview = image;
      });
    },
  },
};
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

.image-preview {
  max-width: 80%;
  max-height: 300px;
}
</style>
