import axios from '../axios';

export const all = (lang = 'pl', params = {}) => {
  return axios.get(`/product-categories?lang=${lang}`, params);
};

export const show = (slug, lang = 'pl', params = {}) => {
  return axios.get(`/product-categories/${slug}?lang=${lang}`, params);
};

export const edit = (data) => {
  return axios.post(`/product-categories/${data.slug}`, data);
};
